import $ from 'jquery';
import Velocity from 'velocity-animate';

export default class Header {
  constructor() {
    this.$el = $('.header');

    this.menuVisible = false;
    this.animating = false;
    this.initEvents();
  }

  initEvents() {
    $(document).on('mousemove', this.checkMouse.bind(this));
    $(window).on('resize', this.checkMenu.bind(this));

    $('.js-toggleMenu').on('click', this.toggleMenu.bind(this));
  }

  toggleMenu(e) {
    if(e) e.preventDefault();

    if(this.menuVisible) {
      this.hideMenu();
    }else{
      this.showMenu();
    }
  }

  checkMouse(e) {
    let mouseY = e.clientY;

    if(mouseY < 40 && !this.menuVisible && !this.animating) {
      this.showMenu();
    }else if(mouseY > this.$el.height() && this.menuVisible && !this.animating) {
      this.hideMenu();
    }
  }

  checkMenu() {
    if(!this.menuVisible) return;

    let menuHeight = this.$el.find('.header__content').height() + 30;
    this.$el.css('height', menuHeight + 'px');
  }

  showMenu() {
    let self = this;
    let menuHeight = this.$el.find('.header__content').height() + 30;
    this.animating = true;
    $('.menu-link').addClass('is-active');
    Velocity.animate(this.$el, {'height': menuHeight+'px'}, 500, 'easeOutQuint', function() {
      this.animating = false;
      this.menuVisible = true;
    }.bind(this));
  }

  hideMenu() {
    let mH = 7;
    if(window.innerWidth < 850) mH = 5;

    let self = this;
    this.animating = true;
    $('.menu-link').removeClass('is-active');
    Velocity.animate(this.$el, {'height': mH + 'px'}, 500, 'easeOutQuint', function() {
      this.animating = false;
      this.menuVisible = false;
      this.$el.css('height', '');
    }.bind(this));
  }
}
