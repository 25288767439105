import '../scss/style.scss';

import $ from 'jquery';

import Header from './header';
let header = new Header();

import Archive from './archive';
let archive = new Archive();

import Intro from './intro';
let intro = new Intro();

