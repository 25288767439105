import $ from 'jquery';
import Velocity from 'velocity-animate';

export default class Archive {
  constructor() {
    this.$el = $('.archive__table');
    this.visibleMobileBlock = '';

    this.initEvents();
  }

  initEvents() {
    $('.js-toggleSummary').on('click', function(e) {
      e.preventDefault();
      let $this = $(e.currentTarget);
      this.toggleSummary($($this.data('parent')));
    }.bind(this));

    $('.js-toggleMobileInfo').on('click', function(e) {
      e.preventDefault();
      let id = $(e.currentTarget).data('target');
      this.toggleMobileInfo(id);
    }.bind(this));

    $('.js-toggleMobilesort').on('click', this.toggleMobilesort.bind(this))

    $(window).on('resize', this.handleResize.bind(this));


  }

  handleResize() {
    // $('.js-summary:visible').each(function() {
    //   $(this).css('height', 'auto');
    //   // let h = $(this).height();
    //   // $(this).css('height', h+'px');
    // });
  }

  toggleMobilesort(e) {
    if(e) e.preventDefault();

    $('.mobilesort').toggleClass('is-expanded');
  }

  toggleMobileInfo(id) {
    if(this.visibleMobileBlock === id) {
      this.hideMobileInfo(id);
    }else{
      this.showMobileInfo(id); 
    }
  }

  showMobileInfo(id) {
    let $target = $('#' + id);
    this.visibleMobileBlock = id;
    $('.archive__table-mobile .block').removeClass('is-active');
    $('.block-hiddeninfo').hide();
    $('.block__infotoggle').html('Read More');
    $target.addClass('is-active');
    $target.find('.block__infotoggle').html('Read Less');
    $target.find('.block-hiddeninfo').fadeIn(200);
  }

  hideMobileInfo(id) {
    let $target = $('#' + id);
    this.visibleMobileBlock = '';
    $target.removeClass('is-active');
    $target.find('.block__infotoggle').html('Read More');
    $target.find('.block-hiddeninfo').hide();
  }

  toggleSummary($target) {
    if($target.hasClass('is-active')) {
      this.hideSummary($target);
    }else{
      this.showSummary($target);
    }
  }

  hideSummary($target) {
    $target.removeClass('is-active');
    let $content = $target.find('.js-summary');
    Velocity.animate($content, {'height': 0}, 400, 'easeInOutSine', function() {
      $content.hide();
    }.bind(this));
  }

  showSummary($target) {
    $target.addClass('is-active');
    let $content = $target.find('.js-summary');
    $content.show();

    $content.each(function(k, v) {
      $(v).css('height', 'auto');
      let h = $(v).height();
      Velocity.animate($(v), {'height': [h+'px', 0]}, 600, 'easeOutQuint', function() {
        $(v).css('height', 'auto');
      }.bind(this));
    }.bind(this));
  }

}
