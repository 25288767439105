import $ from 'jquery';
import Velocity from 'velocity-animate';

export default class Intro {
  constructor() {
    this.$el = $('.intro');

    if(this.$el.length > 0) this.init();
  }

  init() {

    let time = new Date().getTime();

    let localTime = localStorage.getItem('anci-time');
    if(localTime && (time-localTime < 604800)) {
      this.$el.remove();
      return;
    }

    localStorage.setItem('anci-time', time);

    let $intro = this.$el;
    let $content = $('.archive .is-hidden-s, .archive .is-hidden-m');
    $content.css('opacity', 0);
    $('.mobilesort').css('opacity', 0);
    if(window.innerWidth > 850) {
      $('.menu-link, .headline-desktop').css('opacity', 0);
    }

    Velocity.animate($intro, {'opacity': [1, 0]}, 2500, 'linear', function() {
      
      Velocity.animate($intro, {'opacity': [0, 1], 'translateY': ['-50px', 0]}, 800, 'easeInSine', function() {
        $intro.remove();

        Velocity.animate($content, {'opacity': [1, 0], 'translateY': [0, '50px']}, 600, 'easeOutSine');
        Velocity.animate($('.mobilesort'), {'opacity': [1, 0]}, 500);

        if(window.innerWidth > 850) {
          Velocity.animate($('.menu-link, .headline-desktop'), {'opacity': [1, 0]}, 500);
        }
      });
    });

  }
}
